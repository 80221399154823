<template>
  <div id="ajustes">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title text-primary">Ajustes</h4>
        <transition appear name="slide-fade">
          <div>
            <transition v-if="msg.tipo != null" appear name="slide-fade">
              <div class="p-0">
                <div
                  v-bind:class="{
                    'alert-danger': !msg.tipo,
                    'alert-success': msg.tipo,
                  }"
                  class="alert mt-2 mb-2"
                >
                  <i v-if="!msg.tipo" class="fa fa-exclamation-circle"></i>
                  <i v-if="msg.tipo" class="fa fa-check-circle"></i>
                  {{ msg.texto }}
                </div>
              </div>
            </transition>
            <div class="card mt-3">
              <div class="card-header fw-bold">Cambiar contraseña</div>
              <div class="card-body">
                <form v-on:submit.prevent="update_pass">
                  <div class="form-group">
                    <label>Contraseña actual</label>
                    <input
                      v-model="pass"
                      type="password"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group mt-2">
                    <label>Nueva contraseña</label>
                    <input
                      v-model="nuevo_pass"
                      type="password"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group mt-2">
                    <label>Confirma la contraseña</label>
                    <input
                      v-model="confirm_pass"
                      type="password"
                      class="form-control"
                    />
                  </div>

                  <div class="text-right mt-3">
                    <button type="submit" class="btn btn-primary btn-rounded">
                      <i class="icofont-save"></i>
                      Actualizar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Perfil",
  data() {
    return {
      loading: true,
      msg: {
        tipo: null,
        texto: null,
      },
      usuario: null,
      pass: null,
      nuevo_pass: null,
      confirm_pass: null,
    };
  },
  created: function () {
    this.get_perfil();
  },
  methods: {
    get_perfil: function () {
      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      this.usuario = session.usuario.nombres;
    },
    update_pass: function () {
      this.msg = {
        tipo: null,
        texto: null,
      };
      if (this.pass == null || this.pass == "") {
        this.msg.tipo = false;
        this.msg.texto = "Digite la contraseña actual.";
        this.loading = false;
        return false;
      }

      if (this.nuevo_pass == null || this.nuevo_pass == "") {
        this.msg.tipo = false;
        this.msg.texto = "Digite la nueva contraseña.";
        return false;
      }

      if (
        this.confirm_pass == null ||
        this.confirm_pass == "" ||
        this.confirm_pass != this.nuevo_pass
      ) {
        this.msg.tipo = false;
        this.msg.texto = "La nueva contraseña no coincide con la confirmación.";
        return false;
      }

      var session = localStorage.getItem("sess_taqui_web");
      session = JSON.parse(session);
      var token_session = session.token;
      const headers = {
        "Content-Type": "application/json",
        "x-api-key": this.$api_key,
        token: token_session,
      };
      var params = {
        password: this.pass,
        nuevo_password: this.nuevo_pass,
      };
      const vm = this;
      axios
        .put(this.$base_url + "usuarios/update_pass", params, {
          headers: headers,
        })
        .then(function (response) {
          if (response.data.status) {
            vm.msg.tipo = true;
            vm.msg.texto = "Se ha actualizado la contraseña.";
            vm.pass = null;
            vm.nuevo_pass = null;
            vm.confirm_pass = null;
          } else {
            vm.msg.tipo = false;
            vm.msg.texto = response.data.message;
          }
        })
        .catch((error) => {
          if (error == "Error: Network Error") {
            vm.msg.tipo = false;
            vm.msg.texto = "No se ha detecado conexión a internet.";
          } else {
            vm.msg.tipo = false;
            vm.msg.texto = error;
          }
        })
        .finally();
    },
  },
};
</script>